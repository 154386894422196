import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import ExternalLink from '@components/ExternalLink/ExternalLink';
import Spacer from '@components/Spacer/Spacer';
import VideoPlayer from '@components/VideoPlayer/VideoPlayer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const query = graphql`
  query {
    posterImage: file(relativePath: { eq: "media/gigwax/subway-poster.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 928)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Gigwax`}</h1>
    <div className={styles.workDetails}>
  Co-founder, CTO <br /> Brooklyn, NY &mdash; 2014&#8202;&ndash;&#8202;2016
    </div>
    <p>{`Launched a DJ booking marketplace connecting DJs with gigs and facilitating payments to them.`}</p>
    <aside>
  <hr />
    </aside>
    <h2>{`Launch`}</h2>
    <p>{`We hosted a silent-disco style DJ set by Seth Troxler on the M train in Brooklyn, NY.`}</p>
    <p><em parentName="p">{`Click to watch our Subway Rave with Seth Troxler:`}</em></p>
    <VideoPlayer posterImage={getSrc(props.data.posterImage.childImageSharp.gatsbyImageData)} url="/media/gigwax/subway-rave-video/playlist.m3u8" mdxType="VideoPlayer" />
    <h2>{`Features For DJs`}</h2>
    <ul>
      <li parentName="ul">{`As a DJ, log in with your Soundcloud/Mixcloud account to automatically populate your DJ profile`}</li>
      <li parentName="ul">{`Easy payment collection for your gigs`}</li>
      <li parentName="ul">{`Collect reviews`}</li>
    </ul>
    <h2>{`Features For Hosts`}</h2>
    <ul>
      <li parentName="ul">{`Browse thousands of vetted DJs and book them in under a minute`}</li>
      <li parentName="ul">{`Pay with a credit/debit card; Gigwax escrows payment to the DJ pending a successful gig`}</li>
    </ul>
    <h2>{`Marketing`}</h2>
    <p>{`Shot a series of videos:`}</p>
    <ul>
      <li parentName="ul"><ExternalLink href="https://vimeo.com/134991832" mdxType="ExternalLink">
  What is Gigwax?
        </ExternalLink></li>
      <li parentName="ul"><ExternalLink href="https://www.youtube.com/c/Gigwax/videos" mdxType="ExternalLink">
  Gigwax on Youtube
        </ExternalLink></li>
    </ul>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul">{`Archived website `}<ExternalLink href="https://web.archive.org/web/20190116004242/https://www.gigwax.com/" mdxType="ExternalLink">{`
web.archive.org`}</ExternalLink></li>
      <li parentName="ul">{`Press coverage on Alleywatch `}<ExternalLink href="https://www.alleywatch.com/2015/08/tired-of-playing-pandora-step-up-your-game-with-this-nyc-startup/" mdxType="ExternalLink">{`
alleywatch.com`}</ExternalLink></li>
    </ul>
    <aside>
      <hr></hr>
      <p>{`Gigwax disbanded in April 2018, but we like to think that its spirit lives on!`}</p>
    </aside>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      